$border-color-grey: $color-light-gray;

#content_breadcrumb {
  padding: 0px 20px;
  ol.checkout-progress {
    margin-top: 25px;
  }
  li {
    display: inline;
    width: 100%;
    color: $color-dark-gray;
    font-size: 11px;
  }
}

.checkout.review {
  #content_breadcrumb {
    li.review {
      color: $color-dark-black;
      font-weight: bold;
    }
  }
  #continue-checkout-btm {
    padding: 0 1em;
  }
}

#shipping {
  #content_breadcrumb {
    li.shipping {
      color: $color-dark-black;
      font-weight: bold;
    }
  }
}

#confirm {
  #content_breadcrumb {
    li.confirm {
      color: $color-dark-black;
      font-weight: bold;
    }
  }
  fieldset {
    width: auto;
    max-width: none;
    margin-right: 0;
  }
}

#signin-block {
  fieldset {
    .form-item {
      input.field {
        margin-top: 6px;
      }
    }
  }
}

$color-black: $color-darker-gray;
$border-bottom-color: $color-light-gray;

.checkout__content {
  .cart-items__header {
    &--thumb {
      width: 11%;
    }
    &--products {
      width: 40%;
    }
    &--price {
      width: 16%;
    }
    &--qty {
      width: 20%;
    }
    &--total {
      width: 13%;
    }
  }
  .cart-items__item {
    &--desc {
      width: 40%;
    }
    &--price {
      width: 17%;
    }
    &--total {
      width: 12%;
    }
  }
}

#bottom-panel {
  border-top: none;
}

#samples-panel {
  ul.product-list {
    li.product.selected:hover {
      border: 2px solid $color-black;
    }
    li.product.disabled:hover {
      border: none;
    }
  }
}

.review {
  padding: 10px;
  #review-panel {
    border-top: 0;
    .cart-items__item {
      padding: 0px;
    }
  }
  .continue-checkout-btm {
    input[type='submit'] {
      width: 99%;
    }
  }
  .payment-panel {
    .header-section {
      clear: both;
      display: inline-block;
      width: 100%;
    }
    .non-installment-options-header,
    .installment-options-header {
      float: left;
      width: 65%;
    }
    .nccc-media {
      float: right;
      padding: 5px 5px 0 0;
    }
    .non-installment-options,
    .linepay-options,
    .installment-options {
      border: 1px solid $border-color-grey;
      margin: 0 0 10px 0;
    }
    .non-installment-options-header,
    .installment-options-header,
    .linepay-options-header {
      padding: 10px;
    }
    .non-installment-options-radios,
    .installment-options-radios,
    .linepay-options-radios {
      padding-left: 10px;
      margin: 0 0 0.8em 0;
    }
    .nccc-media img {
      height: 25px;
      width: 30px;
    }
    .linepay-options-radios img {
      width: 74px;
      height: 24px;
    }
  }
}

.confirm-page {
  padding: 10px;
  .checkout__panel--content p {
    font-size: 16px;
  }
  .return-link-container {
    padding: 15px;
    text-align: center;
  }
  .go-shopping {
    margin: 20px 0px;
  }
}

.new_user__panel {
  .submit_btn {
    padding: 1em;
  }
  .guest_user {
    margin-top: 1em;
  }
}

.checkout {
  #sign-in-panel {
    .form-item.submit {
      bottom: 15px;
    }
  }
  #signin-block {
    input[type='email'],
    input[type='password'] {
      margin-bottom: 5px;
    }
    .form-item.password {
      padding-bottom: 0px;
    }
    #return-user-input-submit {
      margin-bottom: 20px;
    }
  }
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
  }
  &.shipping,
  &.review,
  &.viewcart,
  &.checkout-single-page {
    margin-top: 50px;
    font-family: $font--text;
    h2 {
      font-family: $font--text;
      font-size: 1.5em;
      letter-spacing: 0;
      &.checkout__panel--heading {
        font-family: $font--subheading;
        font-size: 24px;
      }
    }
    h3 {
      font-family: $font--text;
      font-size: 1.17em;
      letter-spacing: 0;
      &.account-page__section--subheading {
        font-family: $font--subheading;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
      &.offer_code_box_mobile {
        padding-left: 1em;
      }
    }
    h4 {
      font-family: $font--text;
      font-size: 18px;
      letter-spacing: 0;
    }
    p {
      font-size: 18px;
      letter-spacing: 0;
    }
    .newsletter-info__text--new {
      font-size: 18px;
      letter-spacing: 0;
    }
    .trans_detail_item {
      font-family: $font--text;
    }
    .form-submit,
    .checkout-buttons {
      height: 48px;
      line-height: 48px;
    }
    .account-page {
      margin-top: 0;
    }
    #promo-message {
      padding-left: 1em;
    }
    .order-summary-panel__label {
      font-family: $font--subheading;
      letter-spacing: 0;
    }
  }
  &.samples-page {
    &#samples-panel {
      margin-top: 40px;
    }
    .details,
    .product-name,
    .product-items_content,
    .sample-select-button,
    .order-summary-panel__label {
      font-family: $font--subheading;
      letter-spacing: 0;
    }
    .checkout__panel--header {
      .remaining {
        p {
          font-size: 18px;
          letter-spacing: 0;
        }
      }
      .sample_heading {
        font-size: 18px;
        padding-left: 1em;
        letter-spacing: 0;
      }
    }
  }
}

#viewcart,
#shipping {
  #cboxContent {
    #cboxLoadedContent {
      h1 {
        font-family: $font--text;
        font-size: 2em;
      }
      h2 {
        font-family: $font--text;
        letter-spacing: 0;
        font-size: 1.5em;
      }
      p {
        font-family: $font--text;
        font-size: 18px;
      }
      & > div:first-child {
        height: inherit;
        & > .content:first-child {
          height: inherit;
          overflow-y: scroll;
        }
      }
    }
    #address_form_container {
      height: inherit;
      overflow-y: scroll;
    }
    #cboxClose:hover {
      background-position: 0 0;
    }
  }
}

.shipping {
  &.pg_wrapper {
    margin-top: 30px;
    h2 {
      font-family: $font--text;
      font-size: 1.5em;
      letter-spacing: 0;
      &#stage {
        padding-left: 15px;
      }
    }
    h3 {
      font-family: $font--text;
      font-size: 1.17em;
      letter-spacing: 0;
    }
    h4 {
      font-family: $font--text;
      font-size: 18px;
      letter-spacing: 0;
    }
    p {
      font-size: 18px;
      letter-spacing: 0;
    }
    .order-summary-panel__label {
      font-family: $font--subheading;
      letter-spacing: 0;
    }
  }
}

#confirm_checkout {
  .gnav-util--account {
    display: none;
  }
  fieldset.form-guest-submit {
    padding: 0 0 10px 1px;
  }
  #forgot-pass {
    margin-top: 10px;
  }
}

#confirmation-page {
  .new_user__panel {
    padding-top: 20px;
  }
}

.address-overlay.adpl,
.checkout .adpl {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='search'],
  input[type='password'],
  select,
  textarea {
    &.error {
      border-color: $color-red !important;
    }
  }
}

#delivery-options select.error {
  border-color: $color-red;
}

.checkout__content {
  .cart-items__item--qty {
    select {
      height: 32px;
      line-height: 28px;
    }
  }
}

.checkout__panel {
  &--continue-buttons {
    text-align: center;
    .checkout-buttons__container {
      margin-top: 10px;
    }
    .checkout-buttons.button {
      color: $color-white;
    }
  }
  &--header {
    #return-user & {
      margin-top: 20px;
    }
  }
}
$bottom-border-color: $color-light-gray;

#shipping {
  #checkout_shipping {
    #choose-address {
      margin: 15px 0px;
    }
    fieldset.address.fs {
      width: 100%;
      margin: 0;
      fieldset {
        .form-item.radio {
          padding-bottom: 0px;
        }
      }
      div.radio {
        span {
          display: inline;
        }
      }
      div.form_element {
        float: none;
        width: 100%;
        margin: 0px;
        padding-top: 20px;
        input {
          width: 100%;
        }
        .address-search-results {
          display: none;
        }
      }
      div.form_element.default_ship_container {
        label {
          margin-bottom: 15px;
          width: 100%;
          span {
            margin-left: 0px;
          }
        }
      }
    }
    #pickup-options {
      img {
        width: 60px;
        height: 60px;
        padding: 1px;
      }
      label::before {
        margin-right: 8px;
        margin-top: 2px;
      }
      label {
        display: flex;
      }
      .store-btn {
        height: 45px;
        line-height: 3;
        margin: 5px 0;
        a {
          text-decoration: none;
        }
      }
    }
    #deliver-to-me {
      margin-bottom: 0.4em;
      .sub_form.column {
        #address_form_container {
          fieldset.address {
            input[type='email'],
            input[type='password'],
            input[type='text'],
            select {
              margin-top: 0.4em;
            }
          }
        }
      }
      #delivery-options {
        #delivery_method {
          select {
            margin-top: 0.4em;
          }
        }
        #delivery_time_display {
          p {
            margin-bottom: 0px;
          }
          select {
            margin-top: 0.4em;
          }
        }
      }
    }
    .sub-block.billing {
      .address_form_container {
        fieldset[title='your-address'] {
          input[type='email'],
          input[type='password'],
          input[type='text'],
          select {
            margin-top: 0.4em;
          }
        }
      }
    }
    .sub-block.create-account {
      fieldset[title='create-account'] {
        fieldset.fs.checkout_registration {
          input[type='email'],
          input[type='password'],
          input[type='text'],
          select {
            margin-top: 0.4em;
          }
        }
      }
    }
    #small_billing_text {
      .address-container.billing-address {
        float: left;
      }
      ul.address_controls {
        float: right;
      }
    }
    div#corporate-options-wrapper {
      .option.corporate_name,
      .option.corporate_tax_id {
        margin-top: 10px;
        input {
          width: 100%;
          display: block;
        }
      }
    }
    div#invoice-options-wrapper {
      label.invoice-method {
        display: block;
        margin-bottom: 5px;
      }
      .option.carrier-type,
      .option.npoban {
        width: 100%;
      }
      .option.invoice-address {
        input {
          width: 100%;
          display: block;
          margin-bottom: 10px;
        }
      }
      .invoice-options-radio {
        margin-bottom: 20px;
        .invoice-method-options {
          display: block;
        }
      }
      .option.carrier-id {
        input {
          display: block;
        }
      }
    }
    .gift-options-wrapper {
      #to,
      #from {
        margin-bottom: 20px;
        input {
          display: block;
          width: 100%;
        }
      }
      #message {
        textarea {
          display: block;
          margin-bottom: 5px;
          width: 100%;
        }
        #gift-max-char {
          margin-top: 5px;
        }
      }
    }
    div.form_element.delivery_instructions_container {
      textarea {
        width: 100%;
        margin: 10px 0px;
      }
    }
    #delivery-options {
      div.form_element {
        margin-bottom: 10px;
      }
    }
    .giftmsg_message_container {
      input {
        margin-top: 10px;
      }
    }
    .promotions-container {
      margin-bottom: 15px;
    }
  }
  .fieldset__3col.birthday {
    .form_element {
      float: left;
      width: 32%;
      margin: 0px 1% 0px 0px;
      select {
        padding-right: 0px;
        &[name='BIRTH_MONTH'] {
          width: 34%;
        }
      }
    }
  }
  .form_element.radio.privacy_policy_container {
    label {
      padding-top: 2px;
    }
  }
  .trans_detail_item.offer_code.column {
    border-bottom: 1px solid $color-light-gray;
    .offer_code_box {
      input[name='OFFER_CODE'] {
        width: 100%;
      }
      .offer_submit {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  #continue-checkout {
    margin-bottom: 20px;
    width: 99%;
    padding: 0 1em;
  }
}

.invoice-options-container-paper {
  .invoice-address-options {
    label {
      display: block;
      margin: 10px;
    }
  }
}

.checkout__content {
  .cart-items__item {
    &--price {
      font-size: 16px;
    }
    &--total {
      font-size: 16px;
    }
    .price.samples {
      margin-left: 0;
      width: 35%;
    }
    .qty.samples {
      line-height: 1.9;
    }
  }
}

#samples-panel {
  .product-list {
    li.product {
      .skus li.sku {
        width: 200px;
      }
    }
  }
}

.sample-products {
  .sample-select-button {
    font-size: 13px;
    letter-spacing: 0;
  }
}

.shipping-page {
  .address_controls {
    .radio {
      margin-bottom: 10px;
    }
  }
}

.page-wrapper-checkout {
  .page-header__utils {
    .menu--lvl-1 {
      display: none;
    }
    text-align: center;
    min-height: 65px;
    position: relative;
    &__logo {
      float: none;
    }
    .mobile-checkout-back {
      display: block;
      background: url(/media/export/cms/homepage/nav-arrows.png) 12px 9px;
      background-repeat: no-repeat;
      width: 80px;
      height: 50px;
      position: absolute;
      top: 2%;
      left: 2%;
      margin-top: 10px;
    }
    .back-button__text {
      float: right;
      background: $color-white;
      height: 50px;
      padding-top: 14px;
    }
    .utility-nav {
      &__wrapper,
      &__cart,
      &__account-text {
        display: none;
      }
    }
  }
  .gnav-util--search,
  .gnav-util--hamburger,
  .utility-nav-mob {
    display: none;
  }
  &.viewcart-wrapper {
    .mobile-checkout-back {
      display: none;
    }
  }
}
#shipping,
#review {
  .return-link {
    text-align: center;
    text-decoration: underline;
    margin: 10px 0;
    color: $color-grey;
    font-size: 14px;
    font-family: $font--cta;
  }
}
#review-address-info {
  padding: 0 10px;
  .trans_detail_item {
    width: 100%;
    float: none;
    margin-bottom: 0;
    margin-right: 0;
    position: relative;
    word-wrap: break-word;
    padding-bottom: 25px;
    border-bottom: 1px solid $bottom-border-color;
    h2.checkout__panel--heading {
      width: 74%;
      word-wrap: break-word;
      display: block;
      margin: 21px 0;
    }
  }
  a.change_link {
    top: 6px;
    position: absolute;
    right: 0;
  }
}

#review-panel {
  fieldset {
    padding: 0 1em;
  }
}

#review-instructions {
  padding: 0 1em;
}

#checkout_terms {
  label {
    float: left;
    width: 96%;
  }
  span.required {
    float: left;
    width: 3%;
  }
}

#delivery-options select.error {
  border-color: $color-red;
}

#cboxLoadedContent {
  .additional_links {
    padding: 20px;
  }
}

.no_gwp_samples {
  padding-top: 20px;
  .go-shopping {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.lbo {
  &__container {
    .lbo {
      &__message {
        &--content {
          padding-left: 41px;
        }
      }
      &__banner {
        padding-bottom: 8px;
        &--icon-close {
          float: right;
          margin-top: -88px;
          cursor: pointer;
          padding-top: 15px;
        }
      }
    }
  }
}

.page-header {
  &__utils {
    .menu--lvl-1 {
      ul.menu__list {
        li.gnav-util--account {
          width: 50%;
          menu {
            &__link--lvl-1 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.gnav-util {
  &__content {
    .signin-block {
      .signin-block {
        &__main {
          .signin-block {
            &__title {
              margin-bottom: 25px;
            }
          }
          .signin-block {
            &__subtitle {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator {
    .store-locator__main {
      min-height: 600px !important;
      .store-locator__result {
        height: auto;
      }
    }
  }
}

#shipping {
  #main.multi {
    #address_form_container {
      fieldset.fs {
        div.form_element {
          float: left;
          margin: 5px 0px 0px 0px;
          padding: 0;
          input,
          select {
            margin-top: 0px !important;
            margin-bottom: 0px;
          }
          label {
            position: absolute;
            margin-left: 10px;
            display: block;
            background-color: $color-white;
            padding: 0px 4px;
            font-size: 15px;
            color: $color-mid-gray;
            margin-top: calc(0.7em - 1px);
            -webkit-transition: margin 0.5s, font-size 0.5s;
            transition: margin 0.5s, font-size 0.5s;
            width: auto;
          }
        }
        div.form_element.half,
        div.form_element.halfright {
          width: 49%;
        }
        div.form_element.halfright {
          float: right;
        }
        div.form_element.labelmode {
          label {
            font-size: 10px;
            line-height: 9px;
            color: $color-mid-gray;
            margin-top: calc(-0.2em - 1px);
          }
        }
      }
    }
    #billing_address_form_container {
      fieldset.fsb {
        div.form_element {
          float: left;
          margin: 5px 0px 0px 0px;
          padding: 0;
          width: 100%;
          padding-bottom: 0;
          input,
          select {
            margin-top: 0px !important;
            margin-bottom: 0px;
          }
          label {
            position: absolute;
            margin-left: 10px;
            display: block;
            background-color: $color-white;
            padding: 0px 4px;
            font-size: 15px;
            color: $color-mid-gray;
            margin-top: calc(0.7em - 1px);
            -webkit-transition: margin 0.5s, font-size 0.5s;
            transition: margin 0.5s, font-size 0.5s;
          }
        }
        div.form_element.half,
        div.form_element.halfright {
          width: 49%;
        }
        div.form_element.halfright {
          float: right;
        }
        div.form_element.labelmode {
          label {
            font-size: 10px;
            line-height: 9px;
            color: $color-mid-gray;
            margin-top: calc(-0.2em - 1px);
          }
        }
      }
    }
  }
}

#main.multi {
  section.mobile-breadcrumb {
    padding: 5px 10px 5px 10px;
    .content_breadcrumb {
      padding: 0px 0px 15px 0px;
      .checkout-progress {
        float: none;
        li {
          display: block;
          width: 33%;
          margin: 0px;
          text-align: center;
          background-color: #ebebeb;
          height: 20px;
          line-height: 62px;
          float: left;
          padding: 0;
        }
        li:first-of-type {
          border-radius: 20px 0px 0px 20px;
        }
        li:last-of-type {
          border-radius: 0px 20px 20px 0px;
        }
      }
    }
  }
}
$pages: (shipping, review, confirm);

@each $value in $pages {
  .#{$value} {
    #main.multi {
      section.mobile-breadcrumb {
        .content_breadcrumb {
          .checkout-progress li.#{$value} {
            background-color: $color-dark-gray;
          }
        }
      }
    }
  }
}
$pages: (shipping, review, confirm);

@each $value in $pages {
  ##{$value} {
    #header {
      position: fixed;
      top: 0px;
      z-index: 9999;
    }
  }
}

#samples,
#shipping,
#confirm,
#review {
  .page-header {
    .gnav-util--hamburger,
    .gnav-util--account,
    .gnav-util--search,
    .gnav-util--cart {
      display: none;
    }
  }
}

#samples {
  #samples-panel {
    header {
      h2 {
        margin: 10px auto 12px auto;
      }
    }
  }
}

#viewcart,
#samples,
#shipping,
#review {
  #node-4982 {
    display: none;
  }
}

#viewcart,
#samples,
#shipping,
#confirm,
#review {
  .checkout-sticky-btndiv {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4;
    .checkout-sticky-button {
      background-color: $color-darker-gray;
      width: 100%;
      font-size: 18px;
      margin-bottom: 0px !important;
      border: none;
    }
  }
}

#review {
  .review {
    padding-left: 0px;
  }
  .review-content-layout {
    padding-left: 10px;
  }
  .order-summary-panel {
    border-top: none;
  }
}

#review,
#confirm {
  .mobile-breadcrumb {
    ol.checkout-progress {
      margin-top: 0;
    }
  }
}

#viewcart,
#review {
  #main.multi {
    .offer_code {
      fieldset.fs {
        width: 100%;
        div.form_element {
          float: none;
          width: 100%;
          input {
            float: left;
            margin: 0 10px;
            width: 48%;
            height: 45px;
            line-height: 3;
          }
          input.button {
            float: right;
            width: 38%;
          }
        }
      }
    }
    .left_col {
      .offer_code_box {
        padding: 0;
        h3 {
          cursor: pointer;
          font-weight: bold;
          font-family: inherit;
          font-size: 15px;
          margin: 0 0 10px 0;
        }
        .expando-block__icon {
          position: relative;
          float: right;
        }
        .fs {
          margin: 0;
        }
      }
    }
  }
}

#viewcart,
#review {
  h2.checkout__panel--heading {
    display: none;
  }
}

#confirm {
  #node-4982 {
    .menu__item {
      &.gnav-util--account {
        display: block;
      }
    }
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      text-align: center;
      padding: 0px;
      height: 100% !important;
    }
  }
}

#viewcart {
  #cboxOverlay {
    background: none;
  }
}

.elc-user-state-logged-in {
  .user_account_dont_have {
    display: none;
  }
}

.elc-user-state-anonymous {
  .user_account_have {
    display: none;
  }
}

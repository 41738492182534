.profile-info__fieldset,
.profile-form-container {
  .title_container {
    padding-bottom: 1em;
  }
}

.account-qr_section {
  display: flex;
  justify-content: center;
}

#registration-wrapper {
  .form_element {
    width: 100%;
  }
  fieldset.fs {
    width: 100%;
    margin: 0 1.33em 0 0;
    .form_element {
      margin: 0px;
      input {
        width: 100%;
      }
    }
  }
  .fieldset__3col.birthday {
    label[for='birthday-fields'] {
      margin-top: 0.4em;
    }
  }
  .form_element.phone2_container {
    input[type='text'] {
      display: block;
    }
  }
  .email_promotions {
    .form_element.radio {
      width: 100%;
      label {
        width: 100%;
      }
    }
  }
}

.account-page__content {
  .profile-info {
    &__fieldset {
      .national_id_container {
        padding-bottom: 1em;
      }
    }
  }
}

.address-form {
  .title,
  .ship_type {
    span {
      display: block;
      padding-bottom: 5px;
    }
  }
}

.email_promotions {
  .pc_email_promo_container {
    margin-bottom: 10px;
  }
}

#registration_content {
  margin: 30px auto;
}

span.radio_label {
  display: inline !important;
}

#registration_content {
  width: 90%;
  #content {
    width: 100%;
    float: none;
    .radio.title_container {
      margin-top: 20px;
      span {
        display: inline;
      }
    }
    .form_element {
      float: none;
      width: 100%;
      div.radio {
        span {
          display: inline !important;
        }
      }
    }
    .ship_type_container,
    .gender_container {
      label {
        float: none;
      }
      span {
        display: inline;
      }
      span.radio_label {
        display: block !important;
        margin-top: 10px;
      }
    }
    .fieldset__3col.birthday {
      .form_element {
        float: left;
        width: 32.33%;
        margin-left: 1%;
        select {
          padding-right: 0px;
        }
      }
    }
    .button.form-submit {
      width: 99%;
    }
  }
  #sidebar {
    float: none;
    width: 100%;
    margin: 0px;
  }
}

.sign-in-page {
  .account-page__section--heading {
    display: block;
  }
}

.orders-list__table {
  .order-cost,
  .order-date {
    width: auto;
  }
  .order-details {
    .account-products__item--thumb {
      width: 38%;
    }
    .account-products__item--desc {
      width: 62%;
    }
    .product__actions {
      width: 38%;
      padding-right: 0px;
    }
    .product__qty {
      width: 62%;
    }
  }
  span.product__price {
    font-size: 16px !important;
  }
}

.account-products {
  span.price {
    font-size: 16px !important;
  }
}

.order-details-page {
  .order-products {
    .product__price {
      float: left;
      margin-left: 0;
      padding: 0 0.5em;
      width: 10%;
      .price {
        font-size: 18px;
      }
    }
    .product__total {
      .price {
        font-size: 18px;
      }
    }
  }
  .order-totals {
    .order-summary-panel__label {
      font-size: 18px;
    }
    .price {
      font-size: 18px;
    }
  }
  .account-page__section.order-details {
    h4 {
      margin-bottom: 0px;
    }
  }
  .order-products {
    .product__price {
      float: none;
      margin-left: 33.3333333%;
      padding: 0;
      width: auto;
    }
  }
  .order-info {
    &__header {
      .order-info__item:nth-child(1) span a {
        pointer-events: none;
        text-decoration: none;
      }
    }
  }
}

html select::-ms-expand {
  display: none;
}

.product-info {
  .product-offer {
    &__header,
    &__text {
      font-family: SimSun, 'Futura-Book', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
    }
  }
}

.page-footer__logo {
  .kbanklogo {
    text-align: center;
    overflow: hidden;
    img {
      padding-top: 5px;
    }
  }
}

.site-email-signup {
  .site-email-signup__fields {
    input[type='tel'] {
      display: none;
    }
  }
}

.email_promotions {
  #sms-promotions {
    display: none;
  }
}

.favorites-create-wishlist {
  .favorites-create-wishlist__item {
    label {
      display: block;
    }
  }
}

.page-footer {
  .menu__list {
    .trustmark-logo-img {
      width: 65px;
    }
  }
}

.address-item__controls,
.payment-item__controls {
  .link--default {
    margin-top: 5px;
  }
}

#cboxLoadedContent {
  .address-overlay {
    .address-form {
      &__country {
        padding-bottom: 1em;
      }
    }
  }
}

.js-photo-upload-overlay {
  .onlyPhotoUpload {
    height: 240px !important;
  }
}

$address-border: $color-mid-gray;
$address-background-color: $color-light-gray;
$invalid-error-color: $color-red;
$invalid-background: $color-white;

.address-search-results {
  display: none;
}

ul.address-autofill {
  background-color: $address-background-color;
  li {
    padding: 2px 0;
    margin: 5px;
    span {
      margin-right: 5px;
    }
  }
}

.invalid_marker {
  font-size: 2em;
  position: absolute;
  right: 30px;
  bottom: 22px;
  background-color: $invalid-background;
  color: $invalid-error-color;
}

.page-main {
  .page-main__inner {
    .page-console {
      .messages.public {
        ul {
          li {
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
/* ----------- iPhone 4 and 4S ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (max-height: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .gnav-util__content {
    .signin-block {
      .signin-block__authed {
        .signin-block__authed__nav__menu {
          .field-menu {
            .menu--lvl-1 {
              .menu__list {
                .menu__item--lvl-1 {
                  a.menu__link--lvl-1 {
                    line-height: 38px;
                  }
                }
              }
            }
          }
        }
        a.signin-block__authed__nav__signout-link {
          line-height: 38px;
        }
      }
    }
  }
}

#cboxLoadedContent {
  .welcome-15 {
    .site-email-signup {
      &__success--welcome-15 {
        .site-email-signup__success-offer {
          width: 100%;
        }
      }
    }
  }
}

#registration_content {
  #registration-wrapper {
    #required-fields {
      margin-bottom: 10px;
    }
    .submit_btn {
      margin-top: 20px;
    }
  }
}

.section-store-locator {
  .store-locator {
    &__main {
      .doors_results {
        .store-locator {
          &__result {
            .distance {
              width: 30%;
              right: -5px;
            }
          }
        }
      }
    }
  }
}

footer.page-footer {
  .page-footer__secondary {
    .country-chooser {
      .locale-select {
        margin-left: 20px;
      }
      .country-select {
        select.country-select__selectbox {
          width: 150px;
        }
      }
    }
  }
}

.gnav-mini-facials-block {
  &__content {
    margin: 0 10px;
    .minifacials_content {
      text-align: left;
      width: 60%;
    }
    .gnav-mini-facials-block {
      &__cta {
        width: auto;
        font-size: 18px;
      }
    }
  }
}

#appointment-cancellation {
  .appt-book-link {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
    width: auto;
  }
}

.section-book-appointment {
  .appt-book-section {
    .selects-container {
      .selectBox-label {
        display: inline;
      }
    }
  }
}
